.electronic-banners {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
  overflow-y: hidden;
  border-radius: 7px 7px 0 0;
  height: auto;
  background-color: #fff;

  &-overlay {
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1502;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    backface-visibility: hidden;

    &.fade {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      animation-delay: 0s;
      animation-name: fade;
    }
  }

  &-button {
    margin: 15px 0 0;
    height: 60px;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;

    img {
      width: 100%;
      border-radius: 7px;
      height: 60px;
    }
  }

  &__header {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 100px;
      height: 35px;
      position: relative;
      right: 6px;
    }
  }

  &__description {
    font-size: 16px;
    font-weight: 500;
    margin: 10px 15px;
    color: #000;

    &_category {
      color: #98918a;
      font-size: 12px;
      font-weight: 400;
      margin-top: 15px;
    }
  }

  &__close {
    width: 30px;
    height: 30px;
    background-image: url('/static/images/electronic-banners/close.svg');
    background-repeat: no-repeat;
    background-position: right;
  }

  &__list {
    &_i {
      border-radius: 7px;
      overflow: hidden;
      width: 100%;
      text-align: center;

      & + & {
        margin-top: 15px;
      }

      &:last-of-type {
        margin-bottom: 30px;
      }
    }
  }

  &.slide {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-delay: 0s;
    animation-name: slideInUp;
    will-change: transform;
  }
}

@keyframes slideInUp {
  0% {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}
